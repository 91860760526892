
            @import "@/assets/sass/vars.portal.scss";
          














































































































































































































































































































































































































































































































































































.table-responsive {
    //display: block;
    //width: 100%;
    overflow-x: auto !important;

    .table {
        .task-title {
            min-width: 300px;
        }

        .user-name {
            min-width: 200px;
        }
    }
}
