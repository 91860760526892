
            @import "@/assets/sass/vars.portal.scss";
          
















































































































































.v-invoice-plan {
    .bg-gray {
        background: $gray-300 !important;
    }

    &__header {
        color: $headings-color !important;
        font-size: 15px;
        font-weight: bold;
    }
}
