
            @import "@/assets/sass/vars.portal.scss";
          































































































































































































































































































































.contract-timeline {
    .contract-detail-status {
        text-align: center;
        font-size: 13px;
        margin-top: 15px;

        margin-left: 5px;
        margin-right: 5px;
        border-radius: 5px;
        padding-top: 4px;
        padding-bottom: 4px;

        &-0 {
            color: $warning;
            border: 1px solid $warning;
        }

        &-1 {
            color: $success;
            border: 1px solid $success;
        }

        &-2 {
            color: $default;
            border: 1px solid $default;
        }
    }

    .budget-indicator {
        text-align: center;
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .c-date {
        font-size: 12px;
        font-weight: 600;
        color: $headings-color !important;
    }

    .c-length {
        font-size: 10px;
        font-weight: 600;
        color: $gray-600 !important;
    }

    &__elem {
        min-height: 100%;
        padding: 0px;
        &:not(:last-child) {
            //border-right: 1px solid red;
        }
        &:not(:first-child) {
        }
    }

    &__inner {
        border-radius: 6px;
        background: $gray-100;
        padding-left: 4px;
        padding-right: 4px;
        margin-left: 3px;
        margin-right: 3px;
    }

    &__content {
        color: $text-gray;
        font-size: 12px;
        border-radius: 6px;
        border: 1px solid $gray-400;
        background: white;
        padding-left: 4px;
        padding-right: 4px;
        margin-left: 3px;
        margin-right: 3px;
    }

    &__container {
        color: $text-gray;
        font-size: 12px;
        background: white;

        margin-left: 3px;
        margin-right: 3px;
    }

    &__editing {
        margin-top: 15px;
        border-top: 2px dashed $danger;
        border-bottom: 2px dashed $danger;
        border-right: 1px dotted $danger;
        border-left: 1px dotted $danger;
    }
}
