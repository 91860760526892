
            @import "@/assets/sass/vars.portal.scss";
          



































































































































.contract-timeline-empty-box {
    .contract-timeline__inner {
        border: 1px dashed $warning !important;
    }
}

.alert-budget {
    background-color: white;
    color: $warning;
    border-radius: 6px;
}

.striped-background {
    width: 100%;
    height: 100%;
    background: repeating-linear-gradient(
        135deg,
        white,
        white 10px,
        rgb(250, 250, 250) 10px,
        rgb(250, 250, 250) 20px
    );
}
