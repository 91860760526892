
            @import "@/assets/sass/vars.portal.scss";
          

























































































































































































































































































































































































.v-manage-contract {
    .contract-q-dash {
        .hinner-dash-card__left {
            font-size: 16px;
        }
    }

    &.contract-terminated {
        .card-body {
            border: 1px solid $danger !important;
            border-radius: 6px;
        }
    }
}
