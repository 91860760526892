
            @import "@/assets/sass/vars.portal.scss";
          





























































.v-tag {
    width: 47%; 
    margin-bottom: 8px;

    .date-container {
        display: inline-flex;
        flex-direction: row;
        justify-content: space-between;

        width: 100%;

        i {
            padding-top: 5px;
        }
    }
}
