
            @import "@/assets/sass/vars.portal.scss";
          











































































































































































































































































































































































.contract-general-info {
    .smaller-font {
        font-size: 13px;
    }
    dt {
        font-size: 13px;
    }
    dd {
        font-size: 13px;
    }
}
