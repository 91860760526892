
            @import "@/assets/sass/vars.portal.scss";
          















































.hinner-dash-card {

    box-shadow: none;
    border: 1px solid $gray-400; 
    border-radius: 6px;

    

    &__slots {
        display: flex;
        align-items: baseline;
    }

    &__left {
        //border: 1px dotted red;
        font-size: 20px; 
        font-weight: 600;
    }
    

    .card-title {
        font-weight: bold;
    }    

    & .card-body {
        padding: 18px;
    }
   

    &__footer {
        &_content {
            font-size: 13px;
            color: $gray-600;
        }
    }


}
