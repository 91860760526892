
            @import "@/assets/sass/vars.portal.scss";
          





















































































































































.v-contract-timeline-regulation {
    border: 1px solid $gray-600;
    border-radius: 6px;
    margin-left: 12px;
    margin-right: 12px;
    padding: 15px;

    &.regulation-type-3 {
        background-color: $gray-100;
        border: 1px dashed $gray-700;
    }

    .reg-invoice {
        font-size: 14px;
        font-weight: 600;
        color: $default;
    }

    .col {
        text-align: center;

        .field-title {
            color: $text-gray;
            font-size: 11px;
        }
    }

    .cd-status {
        text-align: center;
        border-radius: 6px;
        font-size: 13px;
        padding-top: 4px;
        padding-bottom: 4px;

        &-0 {
            color: $warning;
            border: 1px solid $warning;
        }

        &-1 {
            color: $success;
            border: 1px solid $success;
        }

        &-2 {
            color: $default;
            border: 1px solid $default;
        }
    }
}
