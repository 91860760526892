
            @import "@/assets/sass/vars.portal.scss";
          









































































































































































































































































































































































































































































.v-stats-rp {
    flex: 0 0 300px;
}

.extra-filter {
    .form-group {
        margin-bottom: 0px !important;
    }
}
