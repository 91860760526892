
            @import "@/assets/sass/vars.portal.scss";
          








































































































































































































































































































.sum-line {
    border-top: 3px solid $gray-600 !important;
    td {
        font-weight: bold;
    }
}
